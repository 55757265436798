import {
  Hoeringsmappe,
  Hoeringssag,
  Indberetningsmappe,
  Indlaeg,
  IndlaegTypeIdEnum,
  RessourceRolleIdEnum,
  Sagsbehandlermappe,
} from "interfaces/IIndberetning";
import Section from "components/common/Section";
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Bruger } from "context/AuthProvider";
import { ToastContext } from "context/ToastProvider";
import { ModalContext } from "components/common/Modal/Modal";
import SkrivBeskedModal from "./SkrivBeskedModal";
import {
  isIndberetningAfsluttet,
  isIndberetningMine,
} from "routes/Fip/FipIndberetning";
import { MapContext, setEditFeatureCallback } from "context/MapProvider";
import { finishedDrawingSource, setDrawingCallback } from "config/interactions";
import { convertFeatureToWkt } from "util/ol";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import PlusCircleFill from "components/icons/PlusCircleFill";
import { AddFileModal } from "components/common/Indberet/AddFileModal";
import AddDrawingModal from "components/common/Indberet/AddDrawingModal";
import FileInput from "components/common/FormElements/FileInput";
import { NewFileState } from "routes/Fip/FipIndberet";
import ChatMessage from "./ChatMessage";
import Sagsagt from "./Sagsagt";
import { SagsbehandlingApi } from "api/interfaces";
import { usePrevious } from "hooks/usePrevious";
import { formatDateString } from "util/date";

interface ViewElement {
  date: number;
  component: JSX.Element;
}
interface IChatProps {
  currentUser: Bruger;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  isHoeringssagChat: boolean;
  isStednavn: boolean;
  newFile: NewFileState;
  setNewFile: React.Dispatch<React.SetStateAction<NewFileState>>;
  cancelDrawingAndUploadFile: () => void;
  scrollPos: number | undefined;
  setScrollPos: React.Dispatch<React.SetStateAction<number | undefined>>;
  sagsbehandlingApi: SagsbehandlingApi;
}
// Chooses what messages to show to who
const whitelistSagsbehandling = [
  IndlaegTypeIdEnum.afslutningsKommentar,
  IndlaegTypeIdEnum.fraIndberetter,
  IndlaegTypeIdEnum.tilIndberetter,
  IndlaegTypeIdEnum.fraSagsbehandler,
  IndlaegTypeIdEnum.tilSagsbehandler,
  IndlaegTypeIdEnum.intern,
  IndlaegTypeIdEnum.overdragelseskommentar,
  IndlaegTypeIdEnum.afslutningsKommentar,
];

const whitelistHoeringssag = [
  IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig,
  IndlaegTypeIdEnum.beskedFraHoeringspart,
  IndlaegTypeIdEnum.beskedTiLHoeringsAnsvarlig,
  IndlaegTypeIdEnum.beskedTilHoeringspart,
  IndlaegTypeIdEnum.overdragelseskommentar,
  IndlaegTypeIdEnum.afslutningsKommentar,
];

const rolleTypeToIndlaegType = (
  rolleId: RessourceRolleIdEnum,
  context: "sagsbehandling" | "horingssag",
  isStednavn: boolean
) => {
  // #281
  if (isStednavn) {
    switch (rolleId) {
      case RessourceRolleIdEnum.indberetter:
        return 3;
      case RessourceRolleIdEnum.sagsbehandler:
        return 4;
      default:
        return 4;
    }
  }
  //
  if (context === "sagsbehandling") {
    switch (rolleId) {
      case RessourceRolleIdEnum.indberetter:
        return IndlaegTypeIdEnum.fraIndberetter;
      case RessourceRolleIdEnum.sagsbehandler:
        return IndlaegTypeIdEnum.fraSagsbehandler;
      default:
        return IndlaegTypeIdEnum.fraSagsbehandler;
    }
  } else if (context === "horingssag") {
    switch (rolleId) {
      case RessourceRolleIdEnum.sagsbehandler:
        return IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig;
      case RessourceRolleIdEnum.høringsansvarlig:
        return IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig;
      case RessourceRolleIdEnum.høringspart:
        return IndlaegTypeIdEnum.beskedFraHoeringspart;
      default:
        return IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig;
    }
  } else {
    return IndlaegTypeIdEnum.intern;
  }
};

//TODO: Unhardcode plz
const ændringsTyper = [
  { value: 1, text: "Mangler i kortet (Ny)" },
  { value: 2, text: "Har ændret sig (Rettes)" },
  { value: 3, text: "Er for meget i kortet (Slettes)" },
];

export default function Chat(props: IChatProps) {
  const [chatState, setChatState] = useState<State>("IDLE");
  const {
    currentUser,
    indberetning,
    setIndberetning,
    isHoeringssagChat,
    isStednavn,
    newFile,
    setNewFile,
    cancelDrawingAndUploadFile,
    scrollPos,
    setScrollPos,
    sagsbehandlingApi,
  } = props;
  const {
    getIndberetning,
    getObjekttyper,
    redigerAendringsforslag,
    redigerSagsaktGeometri,
    skrivChatBesked,
    uploadAendringsforslagGeometri,
    uploadFile,
    uploadSagsaktFil,
    uploadSagsaktGeometri,
  } = sagsbehandlingApi;
  const context = isHoeringssagChat ? "horingssag" : "sagsbehandling";

  const { showModal } = useContext(ModalContext);
  const toast = useContext(ToastContext);
  const chatRef = useRef<null | HTMLDivElement>(null);
  const bottomOfChat = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    chatRef.current?.scrollTo({ top: bottomOfChat.current?.offsetTop });
  };
  const { dispatchToMap, mapState } = useContext(MapContext);
  const cancelEditGeometry = useCallback(() => {
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
  }, [dispatchToMap]);

  const { activeDrawTool, editGeometryState } = mapState;
  const [messages, setMessages] = useState<ViewElement[] | undefined>();
  const [objekttyper, setObjekttyper] = useState<
    { value: number; text: string }[]
  >([]);
  const fileInputRef = useRef<HTMLLabelElement>(null);

  const addFile = (fileInfo: {
    beskrivelse: string;
    filId: number;
    geometri: Feature<Geometry>;
    originalFilNavn: string;
  }) => {
    setChatState("IDLE");
    dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
    let mappeid;

    if (!isHoeringssagChat) {
      if (currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler) {
        mappeid = indberetning.sagsbehandlermappe[0].id;
      } else if (currentUser.rolleId === RessourceRolleIdEnum.indberetter) {
        mappeid = indberetning.indberetningsmappe[0].id;
      } else {
        console.error(
          "Non-supported role trying to add a drawing in sagsbehandling!"
        );
      }
    } else {
      if (
        currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
        indberetning.hoeringsansvarligmappe
      ) {
        mappeid = indberetning.hoeringsansvarligmappe[0].id;
      } else if (
        currentUser.rolleId === RessourceRolleIdEnum.høringspart &&
        indberetning.hoeringspartmappe
      ) {
        mappeid = indberetning.hoeringspartmappe[0].id;
      } else {
        console.error(
          "Non-supported role trying to add a drawing in sagsbehandling!"
        );
      }
    }

    uploadSagsaktFil(
      currentUser,
      fileInfo.beskrivelse,
      fileInfo.filId,
      convertFeatureToWkt(fileInfo.geometri),
      fileInfo.originalFilNavn,
      fileInfo.geometri.get("id"),
      `Openlayers.Geometry.${fileInfo.geometri.getGeometry().getType()}`,
      false,
      mappeid
    ).then(() => {
      getIndberetning(indberetning.id, currentUser.personId).then(
        (nyIndberetning) => {
          setIndberetning(nyIndberetning);
          setChatState("IDLE");
        }
      );
    });
  };
  const addDrawing = useCallback(
    (drawingInfo: {
      aendringTypeId: number;
      aendringType: string;
      drawing: Feature<Geometry>;
      featureTypeId: number;
      featureType: string;
      beskrivelse: string;
    }) => {
      setChatState("IDLE");
      dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
      let mappeid;

      if (!isHoeringssagChat) {
        if (currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler) {
          mappeid = indberetning.sagsbehandlermappe[0].id;
        } else if (currentUser.rolleId === RessourceRolleIdEnum.indberetter) {
          mappeid = indberetning.indberetningsmappe[0].id;
        } else {
          console.error(
            "Non-supported role trying to add a drawing in sagsbehandling!"
          );
        }
      } else {
        if (
          currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
          indberetning.hoeringsansvarligmappe
        ) {
          mappeid = indberetning.hoeringsansvarligmappe[0].id;
        } else if (
          currentUser.rolleId === RessourceRolleIdEnum.høringspart &&
          indberetning.hoeringspartmappe
        ) {
          mappeid = indberetning.hoeringspartmappe[0].id;
        } else {
          console.error(
            "Non-supported role trying to add a drawing in sagsbehandling!"
          );
        }
      }

      if (
        currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
        !isHoeringssagChat
      ) {
        uploadSagsaktGeometri(
          currentUser,
          0,
          drawingInfo.beskrivelse,
          drawingInfo.drawing.get("id"),
          convertFeatureToWkt(drawingInfo.drawing),
          `Openlayers.Geometry.${drawingInfo.drawing.getGeometry().getType()}`,
          false,
          mappeid
        ).then(() => {
          getIndberetning(indberetning.id, currentUser.personId).then(
            (nyIndberetning) => {
              setIndberetning(nyIndberetning);
              setChatState("IDLE");
            }
          );
        });
      } else {
        uploadAendringsforslagGeometri(
          currentUser,
          drawingInfo.aendringTypeId,
          drawingInfo.aendringType,
          drawingInfo.beskrivelse,
          drawingInfo.featureType,
          drawingInfo.featureTypeId,
          convertFeatureToWkt(drawingInfo.drawing),
          `Openlayers.Geometry.${drawingInfo.drawing.getGeometry().getType()}`,
          drawingInfo.drawing.get("id"),
          mappeid
        ).then(() => {
          getIndberetning(indberetning.id, currentUser.personId).then(
            (nyIndberetning) => {
              setIndberetning(nyIndberetning);
              setChatState("IDLE");
            }
          );
        });
      }

      //setIndberetning({
      //  type: "ADD_DRAWING",
      //  drawingInfo,
      //});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchToMap]
  );
  // TODO: dette er det fuldstændig samme der sker i FipIndberet, så måske det skal laves mere generel (måske ikke)
  useEffect(() => {
    setDrawingCallback((drawing, isValid) => {
      if (!isValid) {
        finishedDrawingSource.removeFeature(drawing);
        toast({
          type: "danger",
          content: {
            header: "Ugyldig geometri",
            message: `Den angivne geometri er ugyldig, hvilket kan skyldes, at geometrien "krydser" sig selv.\n\nTegn venligst en ny geometri.`,
          },
        });
      } else {
        if (newFile.state === "UPLOADED" && newFile.file) {
          showModal({
            title: "Beskriv filindhold",
            content: (
              <AddFileModal
                filId={parseInt(newFile.file.id)}
                originalFilNavn={newFile.file.originalFilNavn}
                geometri={drawing}
                addFile={(
                  beskrivelse: string,
                  filId: number,
                  geometri: Feature<Geometry>,
                  originalFilNavn: string
                ) => {
                  addFile({
                    beskrivelse,
                    filId,
                    geometri,
                    originalFilNavn,
                  });
                  dispatchToMap({ type: "SET_DRAWTOOL", drawTool: undefined });
                }}
              />
            ),
            closeModalCallback: () => {
              finishedDrawingSource.removeFeature(drawing);
            },
          });
        } else {
          showModal({
            title: "Beskriv indtegning",
            content: (
              <AddDrawingModal
                mappedObjektType={drawing.get("fipFeatureTypeId")}
                drawing={drawing}
                hideObjekttype={
                  currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
                  !isHoeringssagChat
                } // only hide it from the sagsbehandlerin sagsbehandling
                hideAendringstype={
                  currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
                  !isHoeringssagChat
                } // only hide it from the sagsbehandler in sagsbehandling
                submit={(
                  aendringTypeId: number,
                  aendringType: string,
                  drawing: Feature<Geometry>,
                  featureTypeId: number,
                  featureType: string,
                  beskrivelse: string
                ) => {
                  addDrawing({
                    aendringTypeId,
                    aendringType,
                    drawing,
                    featureTypeId,
                    featureType,
                    beskrivelse,
                  });
                }}
                objektTyper={objekttyper}
                ændringsTyper={ændringsTyper}
              />
            ),
            closeModalCallback: () => {
              finishedDrawingSource.removeFeature(drawing);
            },
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatchToMap,
    indberetning,
    newFile.file,
    newFile.state,
    objekttyper,
    isHoeringssagChat,
    showModal,
    addDrawing,
    toast,
  ]);

  const editFeatureCallback = useCallback((feature: Feature<Geometry>) => {
    // Handle errors (toast maybe)

    // find the sagsakt in the correct "folder"
    if (feature.get("type").toLowerCase() === "geometri") {
      if (
        currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
        !isHoeringssagChat
      ) {
        redigerSagsaktGeometri(
          currentUser,
          feature.get("id"),
          feature.get("beskrivelse"),
          convertFeatureToWkt(feature)
        ).then(() => {
          getIndberetning(indberetning.id, currentUser.personId).then(
            (nyIndberetning) => {
              setIndberetning(nyIndberetning);
              setChatState("IDLE");
            }
          );
        });
      } else {
        redigerAendringsforslag(
          currentUser,
          feature.get("aendringTypeId"),
          feature.get("aendringType"),
          feature.get("id"),
          feature.get("beskrivelse"),
          feature.get("featureType"),
          feature.get("featureTypeId"),
          convertFeatureToWkt(feature),
          feature.getGeometry().getType(),
          `Openlayers.Geometry.${feature.getGeometry().getType()}`
        ).then(() => {
          getIndberetning(indberetning.id, currentUser.personId).then(
            (nyIndberetning) => {
              setIndberetning(nyIndberetning);
              setChatState("IDLE");
            }
          );
        });
      }
    } else {
      console.error("Feature type not recognized");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setEditFeatureCallback(editFeatureCallback);
  }, [editFeatureCallback]);

  useEffect(() => {
    setMessages(renderMessages(indberetning, currentUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indberetning, currentUser, objekttyper]);

  useEffect(() => {
    if (editGeometryState.active || activeDrawTool) {
      setChatState("LOADING");
    } else if (!editGeometryState.active && !activeDrawTool) {
      setChatState("IDLE");
      cancelDrawingAndUploadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editGeometryState.active, activeDrawTool]);

  const prevNumMessages = usePrevious(messages)?.length ?? 0;
  useLayoutEffect(() => {
    const currNumMessages = messages?.length ?? 0;
    if (currNumMessages - prevNumMessages === 1 || !scrollPos) {
      scrollToBottom();
    } else {
      chatRef.current?.scrollTo({ top: scrollPos });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
  useEffect(() => {
    const getObjekttyperForTema = async () => {
      const ot = await getObjekttyper(indberetning.domaeneTypeId);
      setObjekttyper(ot);
    };
    getObjekttyperForTema();
  }, [indberetning.domaeneTypeId, getObjekttyper]);

  const renderMessages = (
    indberetning: Hoeringssag,
    currentUser: Bruger
  ): ViewElement[] => {
    let viewElements: ViewElement[] = [];
    if (indberetning.korrespondance) {
      indberetning.korrespondance.forEach((k: Indlaeg) => {
        const { typeId } = k;
        const whitelist = isHoeringssagChat
          ? whitelistHoeringssag
          : whitelistSagsbehandling;
        if (whitelist.includes(typeId)) {
          viewElements.push({
            date: Date.parse(k.ts),
            component: (
              <ChatMessage
                type={
                  isStednavn
                    ? "Stednavn"
                    : isHoeringssagChat
                    ? "Høringssag"
                    : "Sagsbehandling"
                }
                indlaeg={k}
              />
            ),
          });
        }
      });
    }
    if (isHoeringssagChat) {
      if (indberetning.hoeringsansvarligmappe) {
        indberetning.hoeringsansvarligmappe.forEach((k: Hoeringsmappe) => {
          const isMe =
            currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
            indberetning.ressource !== null; // If the ressource is null, it's not supposed to be viewed
          // const isMeToo = isIndberetningMine(
          //   currentUser,
          //   indberetning,
          //   isHoeringssagChat
          // );
          if (k.geometri) {
            k.geometri.forEach((g) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={false}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
          if (k.fil) {
            k.fil.forEach((g: any) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={false}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
        });
      }

      // Høringspartmappen
      if (indberetning.hoeringspartmappe) {
        indberetning.hoeringspartmappe.forEach((k: Hoeringsmappe) => {
          const isMe =
            currentUser.rolleId === RessourceRolleIdEnum.høringspart &&
            indberetning.ressource !== null; // If the ressource is null, it's not supposed to be viewed
          if (k.geometri) {
            k.geometri.forEach((g) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={true}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
          if (k.fil) {
            k.fil.forEach((g: any) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={true}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
        });
      }
    } else {
      // Løb igennem indberetningsmappen
      if (indberetning.indberetningsmappe) {
        indberetning.indberetningsmappe.forEach((k: Indberetningsmappe) => {
          const isMe =
            currentUser.rolleId === RessourceRolleIdEnum.indberetter &&
            indberetning.ressource !== null; // If ressource is null, the indberetter is not the owner
          if (k.geometri) {
            k.geometri.forEach((g) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  // TODO: #280: Disable delete for indberetter geometri stednavn
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={false}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
          if (k.fil) {
            k.fil.forEach((g: any) => {
              viewElements.push({
                date: Date.parse(g.dato),
                component: (
                  <Sagsagt
                    currentUser={currentUser}
                    fromBehandler={false}
                    isMe={isMe}
                    indberetning={indberetning}
                    setIndberetning={setIndberetning}
                    indlaeg={g}
                    isHoeringssagChat={isHoeringssagChat}
                    isStednavn={isStednavn}
                    setChatState={setChatState}
                    cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                    onEditGeometry={(feature) => {
                      setChatState("LOADING");
                      cancelDrawingAndUploadFile();
                      dispatchToMap({
                        type: "EDIT_GEOMETRY",
                        geometryToEdit: feature,
                      });
                    }}
                    objektTyper={objekttyper}
                    ændringsTyper={ændringsTyper}
                    sagsbehandlingApi={sagsbehandlingApi}
                  />
                ),
              });
            });
          }
        });
      }
      // Løb igennem sagsbehandlermappen
      if (indberetning.sagsbehandlermappe) {
        indberetning.sagsbehandlermappe.forEach((k: Sagsbehandlermappe) => {
          const isMe =
            currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
            indberetning.ressource !== null; // If ressource is null, the indberetter is not the owner;
          k.geometri.forEach((g) => {
            viewElements.push({
              date: Date.parse(g.dato),
              component: (
                <Sagsagt
                  currentUser={currentUser}
                  fromBehandler={true}
                  isMe={isMe}
                  indberetning={indberetning}
                  setIndberetning={setIndberetning}
                  indlaeg={g}
                  isHoeringssagChat={isHoeringssagChat}
                  isStednavn={isStednavn}
                  setChatState={setChatState}
                  cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                  onEditGeometry={(feature) => {
                    setChatState("LOADING");
                    cancelDrawingAndUploadFile();
                    dispatchToMap({
                      type: "EDIT_GEOMETRY",
                      geometryToEdit: feature,
                    });
                  }}
                  objektTyper={objekttyper}
                  ændringsTyper={ændringsTyper}
                  sagsbehandlingApi={sagsbehandlingApi}
                />
              ),
            });
          });
          k.fil.forEach((g: any) => {
            viewElements.push({
              date: Date.parse(g.dato),
              component: (
                <Sagsagt
                  currentUser={currentUser}
                  fromBehandler={true}
                  isMe={isMe}
                  indberetning={indberetning}
                  setIndberetning={setIndberetning}
                  indlaeg={g}
                  isHoeringssagChat={isHoeringssagChat}
                  isStednavn={isStednavn}
                  setChatState={setChatState}
                  cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                  onEditGeometry={(feature) => {
                    setChatState("LOADING");
                    cancelDrawingAndUploadFile();
                    dispatchToMap({
                      type: "EDIT_GEOMETRY",
                      geometryToEdit: feature,
                    });
                  }}
                  objektTyper={objekttyper}
                  ændringsTyper={ændringsTyper}
                  sagsbehandlingApi={sagsbehandlingApi}
                />
              ),
            });
          });
        });
      }
    }

    // Sorter listen
    viewElements.sort(function (a, b) {
      return a.date - b.date;
    });
    return viewElements;
  };

  useEffect(() => {
    return () => {
      dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
      dispatchToMap({
        type: "SET_DRAWTOOL",
        drawTool: undefined,
      });
    };
  }, [dispatchToMap]);
  const skrivBesked = (
    currentUser: Bruger,
    besked: string,
    typeId: IndlaegTypeIdEnum,
    sagsId: number
  ) => {
    let indlaeg: Indlaeg = {
      id: 0,
      indhold: besked,
      organisationNavn: "",
      personId: currentUser.personId,
      personNavn: currentUser.navn,
      typeId: typeId,
      typeNavn: "",
      organisationId: 0,
      organisationForkortelse: "",
      ts: "",
    };

    const nytIndlaeg = skrivChatBesked(currentUser.personId, sagsId, indlaeg);

    return nytIndlaeg;
  };
  const senestOpdateret =
    messages && messages.length > 0
      ? formatDateString(
          new Date(messages[messages.length - 1].date).toString()
        )
      : "";
  const senestOpdateretVoiceOver =
    messages && messages.length > 0 ? (
      <span className="visually-hidden">
        Senest opdateret:{" "}
        <span>
          {formatDateString(
            new Date(messages[messages.length - 1].date).toString(),
            "dd-MM-yyyy"
          )}
        </span>{" "}
        klokken{" "}
        {formatDateString(
          new Date(messages[messages.length - 1].date).toString(),
          "HH:mm"
        )}
      </span>
    ) : (
      ""
    );

  // #296, #274, #324 (hvornår skal knapper under chat vises)
  const showButtons =
    isIndberetningMine(currentUser, indberetning, isHoeringssagChat) &&
    !isIndberetningAfsluttet(indberetning) &&
    !(
      !isHoeringssagChat &&
      currentUser.rolleId === RessourceRolleIdEnum.høringspart
    );

  return (
    <div
      className="active"
      id={isHoeringssagChat ? "sags-horing" : "sags-sagsbehandling"}
      role="tabpanel"
      aria-labelledby={
        isHoeringssagChat ? "sags-horing-tab" : "sags-sagsbehandling-tab"
      }
    >
      <Section className="mt-4">
        {/* {isHoeringssagChat && (
          <div className="alert alert-horing small d-flex mb-4" role="alert">
            <div className="alert-icon">
              <IconInformation />
            </div>
            <div>
              <span className="alert-heading d-block mb-1">
                Høringssag ses kun af sagsbehandler og høringsparter
              </span>

              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat.
              </p>
              <p className="mb-0 text-dark">
                <strong>Ønsker du mere information</strong>
              </p>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat.Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                dolore magna aliquyam erat.
              </p>
            </div>
          </div>
        )} */}
        {isHoeringssagChat && (
          <>
            <h2>Høringssag</h2>
            <p>Oversigt over høringssag.</p>
          </>
        )}
        {!isHoeringssagChat && (
          <>
            <h2>Sagsbehandlings journal</h2>
            <p>Oversigt over sagsbehandlingen.</p>
          </>
        )}

        {(indberetning.korrespondance ||
          indberetning.indberetningsmappe ||
          indberetning.sagsbehandlermappe) && (
          <div
            className={`c-journal ${
              isHoeringssagChat
                ? `c-journal--horing`
                : `c-journal--sagsbehandling`
            }`}
            style={{ flex: 1 }}
          >
            <div className="c-journal__head" aria-live="polite">
              {chatState === "LOADING" && (
                <span className="small loading align-items-center">
                  <strong className="me-2 fw-semibold">
                    Opdaterer journal
                  </strong>
                  <span
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </span>
              )}
              {messages && messages.length > 0 && chatState === "IDLE" && (
                <>
                  <span
                    aria-hidden
                    className="small loading align-items-center"
                  >
                    <strong className="me-2 fw-semibold">
                      Senest opdateret:
                    </strong>
                    {senestOpdateret}
                  </span>
                  {senestOpdateretVoiceOver}
                </>
              )}
              {messages && messages.length === 0 && (
                <div className="small loading align-items-center bg-grey w-100 rounded py-3 px-4 mt-3 d-block">
                  <strong className="me-2 fw-semibold">
                    Ingen beskeder endnu
                  </strong>
                  <p className="mb-0">Her vises journalisering for sagen</p>
                </div>
              )}
            </div>
            <div>
              <div
                className="c-journal__content d-flex flex-column-reverse p-12"
                ref={chatRef}
                onScroll={(e) => {
                  setScrollPos((e.target as any).scrollTop);
                }}
              >
                <div ref={bottomOfChat}></div>
                {messages
                  ?.slice()
                  .reverse()
                  .map((m, i) => {
                    return <Fragment key={i}>{m.component}</Fragment>;
                  })}
              </div>
            </div>
            {showButtons && (
              <div className="c-journal__footer d-lg-flex gap-2 pe-2 pt-2 ps-2 pb-1 pb-lg-2">
                <button
                  type="button"
                  className="btn btn-primary mb-1 me-1 mb-lg-0 me-lg-0"
                  onClick={() => {
                    let type;
                    if (isHoeringssagChat) {
                      currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler // This is a høringssanvarlig apparently
                        ? (type = IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig)
                        : (type = IndlaegTypeIdEnum.beskedFraHoeringspart);
                    } else {
                      currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler
                        ? (type = IndlaegTypeIdEnum.fraSagsbehandler)
                        : (type = IndlaegTypeIdEnum.tilIndberetter);
                    }
                    setChatState("LOADING");
                    cancelDrawingAndUploadFile();
                    showModal({
                      content: (
                        <SkrivBeskedModal
                          type={type}
                          skrivBesked={(besked: string) => {
                            skrivBesked(
                              currentUser,
                              besked,
                              rolleTypeToIndlaegType(
                                currentUser.rolleId,
                                context,
                                isStednavn
                              ),
                              indberetning.id
                            ).then((nytIndlaeg) => {
                              setIndberetning({
                                ...indberetning,
                                korrespondance: [
                                  ...indberetning.korrespondance,
                                  nytIndlaeg,
                                ],
                              });
                              setChatState("IDLE");
                            });
                          }}
                        />
                      ),
                      title: "Ny besked",
                      closeModalCallback: () => {
                        setChatState("IDLE");
                      },
                    });
                  }}
                >
                  Skriv besked
                </button>
                {currentUser.rolleId !== RessourceRolleIdEnum.indberetter &&
                  !isHoeringssagChat && (
                    <button
                      type="button"
                      className="btn btn-primary mb-1 me-1 mb-lg-0 me-lg-0"
                      onClick={() => {
                        setChatState("LOADING");
                        cancelDrawingAndUploadFile();
                        showModal({
                          title: "Intern note",
                          content: (
                            <SkrivBeskedModal
                              type={IndlaegTypeIdEnum.intern}
                              skrivBesked={(besked: string) => {
                                skrivBesked(
                                  currentUser,
                                  besked,
                                  IndlaegTypeIdEnum.intern,
                                  indberetning.id
                                ).then((nytIndlaeg) => {
                                  setIndberetning({
                                    ...indberetning,
                                    korrespondance: [
                                      ...indberetning.korrespondance,
                                      nytIndlaeg,
                                    ],
                                  });
                                  setChatState("IDLE");
                                });
                              }}
                            />
                          ),
                          closeModalCallback: () => {
                            setChatState("IDLE");
                          },
                        });
                      }}
                    >
                      Intern note
                    </button>
                  )}
                {!(
                  isStednavn &&
                  currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler
                ) && (
                  <div className="d-inline-block align-middle mb-1 me-1 mb-lg-0 me-lg-0">
                    {newFile.state !== "UPLOADED" && (
                      <FileInput
                        marginBottom={0}
                        ref={fileInputRef}
                        onChange={(e) => {
                          cancelEditGeometry();
                          setNewFile({ state: "UPLOADING", file: undefined });
                          const { files } = e.target;
                          if (files && files.length) {
                            const file = files[0];
                            let formData = new FormData();
                            formData.append("file", file);
                            uploadFile(formData).then((resp) => {
                              setNewFile({
                                state: "UPLOADED",
                                file: {
                                  id: resp.id,
                                  originalFilNavn: resp.originalFilNavn,
                                },
                              });
                            });

                            dispatchToMap({
                              type: "SET_DRAWTOOL",
                              drawTool: "point",
                            });
                          } else {
                            // Fortryd tegning
                            cancelDrawingAndUploadFile();
                          }
                        }}
                      />
                    )}
                    {newFile.state === "UPLOADED" && (
                      <button
                        type="button"
                        className="btn btn-danger text-white"
                        onClick={() => {
                          cancelDrawingAndUploadFile();
                        }}
                      >
                        <PlusCircleFill class="me-2 rotate-45" />
                        Fortryd upload
                      </button>
                    )}
                  </div>
                )}

                {!(
                  isStednavn &&
                  currentUser.rolleId === RessourceRolleIdEnum.indberetter
                ) && (
                  <button
                    type="button"
                    className={`btn btn-primary mb-1 me-1 mb-lg-0 me-lg-0 ${
                      activeDrawTool && newFile.state !== "UPLOADED"
                        ? "btn-danger text-white"
                        : ""
                    }`}
                    onClick={() => {
                      if (!activeDrawTool || newFile.state === "UPLOADED") {
                        // Aktiver tegning
                        setChatState("LOADING");
                        cancelEditGeometry();
                        setNewFile({ state: "IDLE", file: undefined });
                        dispatchToMap({
                          type: "SET_DRAWTOOL",
                          drawTool: "point",
                        });
                      } else {
                        // Fortryd tegning
                        cancelDrawingAndUploadFile();
                      }
                    }}
                  >
                    {(!activeDrawTool || newFile.file) && (
                      <>
                        <PlusCircleFill class="me-2" />
                        Tegn opdatering
                      </>
                    )}
                    {activeDrawTool && !newFile.file && (
                      <>
                        <PlusCircleFill class="me-2 rotate-45" />
                        Fortryd tegning
                      </>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </Section>
    </div>
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
}
