import ChevronRight from "components/icons/ChevronRight";
import { Link } from "react-router-dom";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { Fil } from "routes/Fip/FipIndberet";
import { routes } from "routes/routesConfig";
import { Validation } from "interfaces/IIndberetning";
import { useLayoutEffect, useRef } from "react";

type ÆndringsType = "opret" | "ændre" | "nedlæg" | undefined;
export interface NyIndberetningState {
  ændringstype: ÆndringsType;
  vilkårAccepteret: boolean;
  nytStednavn: string;
  beskrivelse: string;
  emne: string;
  geometri?: Feature<Geometry>;
  fil?: Fil;
  email: string;
  validation: {
    ændringstype: Validation;
    nytStednavn: Validation;
    vilkårAccepteret: Validation;
    beskrivelse: Validation;
    filEllerGeometri: Validation;
    email: Validation;
    emne: Validation;
  };
}
export default function StednavnIndberet() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div>
      <div className="mt-5">
        <h1 tabIndex={-1} ref={titelRef}>
          Danske Stednavne
        </h1>
        <p className="lead fw-semibold text-dark">Type af indberetning</p>
        <p>
          Har du ønsker om at oprette et nyt stednavn, ændre et eksisterende 
          stednavn, eller nedlægge et eksisterende stednavn? Vælg herunder.
        </p>
        {/* section */}
        <section className="l-section">
          {/* section head */}
          <h2 className="mb-3">Vælg indberetningstype</h2>
          {/* section content - component select */}
          <div className="list-group mb-2">
            <Link
              to={routes.stednavnIndberetCreate.path}
              className="list-group-item d-flex px-2 justify-content-between align-items-center list-group-item-action mb-2 border"
              aria-current="true"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Opret stednavn</div>
                Opret et ikke-registreret stednavn i kortet.
              </div>
              <span className="badge text-dark rounded-pill">
                <ChevronRight />
              </span>
            </Link>
            <Link
              to={routes.stednavnIndberetUpdate.path}
              className="list-group-item d-flex px-2 justify-content-between align-items-center list-group-item-action mb-2 border"
              aria-current="true"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Ændre stednavn</div>
                Foretag ændring i et eksisterende stednavn.
              </div>
              <span className="badge text-dark rounded-pill">
                <ChevronRight />
              </span>
            </Link>
            <Link
              to={routes.stednavnIndberetDelete.path}
              className="list-group-item d-flex px-2 justify-content-between align-items-center list-group-item-action border"
              aria-current="true"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Nedlæg stednavn</div>
                Nedlæg et eksisterende stednavn.
              </div>
              <span className="badge text-dark rounded-pill">
                <ChevronRight />
              </span>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}
