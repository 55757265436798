export default function ModalBetingelser(props: {
  closeModal: (withCallback: boolean) => void;
}) {
  const { closeModal } = props;
  return (
    <div>
      <p>
        En betingelse for betingelser er at betingelserne ikke er betinget men
        hellere tinget.
      </p>
      <button className="btn btn-primary" onClick={() => closeModal(false)}>
        Okey dokey
      </button>
    </div>
  );
}
