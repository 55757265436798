import WMTSTileGrid from "ol/tilegrid/WMTS";
import Projection from "ol/proj/Projection.js";
import { addProjection } from "ol/proj.js";
import { register } from "ol/proj/proj4.js";
import { getTopLeft } from "ol/extent.js";
import proj4 from "proj4";
import { Map } from "ol";
import { defaults as defaultControls, ScaleLine } from "ol/control.js";
import { defaults as defaultInteractions } from "ol/interaction.js";
import View from "ol/View.js";

export const projection = new Projection({
  code: "EPSG:25832",
  extent: [120000, 5661139.2, 958860.8, 6500000],
  units: "m",
});
addProjection(projection);
proj4.defs(
  "EPSG:25832",
  "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);
export const extent: [number, number, number, number] = [
  120000, 5904000, 958860.8, 6499000,
];

export const resolutions = [
  1638.4, 819.2, 409.6, 204.8, 102.4, 51.2, 25.6, 12.8, 6.4, 3.2, 1.6, 0.8, 0.4,
  0.2, 0.1, 0.05,
];
export const matrixIds = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];
const projectionExtent = projection.getExtent();
const origin = getTopLeft(projectionExtent);
export const tileGrid = new WMTSTileGrid({
  origin,
  resolutions,
  matrixIds,
});

export const createMap = (target: string) => {
  return new Map({
    target,
    controls: defaultControls({
      attribution: false,
      zoom: true,
    }).extend([new ScaleLine()]),
    interactions: defaultInteractions({
      altShiftDragRotate: false,
      pinchRotate: false,
    }),
    view: new View({
      center: [704845, 6192783],
      extent: [390000, 5900000, 920000, 6600000],
      zoom: 4,
      maxResolution: 819.2,
      maxZoom: 12,
      enableRotation: false,
      projection: projection,
    }),
  });
};
