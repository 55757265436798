import { ForwardedRef, forwardRef } from "react";
import ValidationErrorMessage from "../FormElements/ValidationErrorMessage";
const EmailInput = forwardRef(
  (
    props: {
      email: string;
      setEmail: (email: string) => void;
      disableForm: boolean;
      showValidationWarning: boolean;
      validationWarningMessage: string;
      notifyValidationWarning: boolean;
      resetNotifyValidationWarning: () => void;
    },
    ref: ForwardedRef<HTMLLabelElement>
  ) => {
    const {
      disableForm,
      email,
      setEmail,
      showValidationWarning,
      validationWarningMessage,
      notifyValidationWarning,
      resetNotifyValidationWarning,
    } = props;
    return (
      <div className="col-md-12">
        <label ref={ref} htmlFor="inputEmail4" className="form-label">
          Email
        </label>
        <input
          disabled={disableForm}
          type="email"
          className={
            showValidationWarning ? "form-control is-invalid" : "form-control"
          }
          id="inputEmail4"
          placeholder="Fx sdfe@sdfe.dk"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
        <ValidationErrorMessage
          message={validationWarningMessage}
          show={showValidationWarning}
          notify={notifyValidationWarning}
          resetNotify={resetNotifyValidationWarning}
        />
      </div>
    );
  }
);

export default EmailInput;
