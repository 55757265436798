import { useContext, useLayoutEffect, useRef, useState } from "react";
import ValidationErrorMessage from "../FormElements/ValidationErrorMessage";
import { ModalContext } from "../Modal/Modal";

export default function AfslutIndberetningModal(props: {
  afslutIndberetning: (kommentar: string) => void;
}) {
  const firstFocusRef = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { afslutIndberetning } = props;
  const [kommentar, setKommentar] = useState("");
  const [notify, setNotify] = useState(false);
  const [didPressSend, setDidPressSend] = useState(false);
  return (
    <div>
      <label htmlFor="Kommentar" className="form-label">
        Kommentar <span className="text-danger ms-1" aria-label="(Skal udfyldes)" title="Skal udfyldes">
          <svg className="mt-n1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" aria-hidden="true" focusable="false" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
          </svg>
        </span>
      </label>
      <textarea
        aria-required="true"
        ref={firstFocusRef}
        className={`form-control ${
          !kommentar && didPressSend ? "is-invalid" : ""
        }`}
        id="beskrivelse"
        value={kommentar}
        onChange={(e) => setKommentar(e.target.value)}
      ></textarea>
      <ValidationErrorMessage
        show={!kommentar && didPressSend}
        message="Skriv en kommentar"
        notify={notify}
        resetNotify={() => {
          setNotify(false);
        }}
      />
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (!kommentar) {
              setDidPressSend(true);
              setNotify(true);
            } else {
              afslutIndberetning(kommentar);
              closeModal(false);
            }
          }}
        >
          Send
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
