import FeatureItem from "components/common/Indberetning/FeatureItem";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { NewFileState } from "routes/Fip/FipIndberet";
import PlusCircleFill from "components/icons/PlusCircleFill";
import { DrawTool } from "context/MapProvider";
import DrawInstructions from "components/common/Indberet/DrawInstructions";
import { ShowModalSignature } from "components/common/Modal/Modal";

export default function Drawing(props: {
  type: "create" | "update";
  setDrawTool: (drawTool: DrawTool) => void;
  activeDrawTool: DrawTool;
  newFile: NewFileState;
  onDeleteClick: Function;
  kategori: string;
  geometri: Feature<Geometry> | undefined;
  hoveredFeatures: (string | number)[];
  setHoveredFeatures: (featureIds: (string | number)[]) => void;
  zoomToDrawing: (drawing: Feature<Geometry>) => void;
  onEditGeometryClick: () => void;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
}) {
  const {
    type,
    geometri,
    onDeleteClick,
    hoveredFeatures,
    setHoveredFeatures,
    zoomToDrawing,
    kategori,
    newFile,
    activeDrawTool,
    setDrawTool,
    onEditGeometryClick,
    showModal,
    closeModal,
  } = props;

  return (
    <section className={`l-section`}>
      <h2>
        {type === "create" && "Indtegn stedet som dit stednavn er tilknyttet"}
        {type === "update" && "Ændre stednavnet i kortet"}
      </h2>
      <p>
        {type === "create" &&
          "I kortet til højre skal du oplyse os om stedet, som din indberetning omhandler. Du kan indtegne det med et punkt, en linje eller en polygon."}
        {type === "update" &&
          "I kortet til højre kan du indtegne en ændring til placeringen eller udstrækningen af stednavnet. Du kan indtegne det med et punkt, en linje eller en polygon."}
      </p>
      <div className="c-draw mb-3">
        {geometri === undefined && (
          <>
            <span className="d-block bg-tint-primary text-shade-primary fw-normal py-2 px-3 mb-3">
              Du har endnu ikke tegnet en geometri.
            </span>
            {!newFile.file && (
              <div className="d-flex align-items-center mt-2">
                {!activeDrawTool && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setDrawTool("point");
                    }}
                  >
                    <PlusCircleFill class="me-2" />
                    Indtegn sted
                  </button>
                )}
                {activeDrawTool && (
                  <button
                    type="button"
                    className="btn btn-primary btn-danger text-white"
                    onClick={() => {
                      setDrawTool(undefined);
                    }}
                  >
                    <PlusCircleFill class="me-2 rotate-45" />
                    Fortryd tegning
                  </button>
                )}
                <div className="ms-3">
                  {activeDrawTool && (
                    <DrawInstructions activeDrawTool={activeDrawTool} />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {geometri && (
          <FeatureItem
            onDeleteClick={onDeleteClick}
            kategori={kategori}
            feature={geometri}
            hovered={hoveredFeatures.indexOf(geometri.get("id")) > -1}
            setHoveredFeatures={(ids) => {
              setHoveredFeatures(ids);
            }}
            zoomToDrawing={zoomToDrawing}
            onEditGeometryClick={onEditGeometryClick}
            showModal={showModal}
            closeModal={closeModal}
          />
        )}
      </div>
    </section>
  );
}
