import { FipLayer } from "interfaces/FipLayer";
import {
  topoSkærmkort,
  stednavnSagWMSLayer,
  geodanmark,
  ortofoto,
  kommunegrænser,
  adresser,
  matrikler,
  stednavneWMS,
  stednavneWFSLayer,
} from "./layers";

export const baselayers: FipLayer[] = [
  {
    loading: false,
    maplayer: geodanmark,
    title: geodanmark.get("title"),
    visible: true,
  },
  {
    loading: false,
    maplayer: topoSkærmkort,
    title: topoSkærmkort.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: ortofoto,
    title: ortofoto.get("title"),
    visible: false,
  },
];
export const layers: FipLayer[] = [
  {
    loading: false,
    maplayer: kommunegrænser,
    title: kommunegrænser.get("title"),
    visible: false,
  },
  // postdistrikter,
  // sogne,
  {
    loading: false,
    maplayer: adresser,
    title: adresser.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: matrikler,
    title: matrikler.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: stednavneWMS,
    title: stednavneWMS.get("title"),
    visible: true,
  },
  {
    loading: false,
    maplayer: stednavneWFSLayer,
    title: stednavneWFSLayer.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: stednavnSagWMSLayer,
    title: stednavnSagWMSLayer.get("title"),
    visible: true,
  },
];

const FipConfig = { baselayers, layers };
export default FipConfig;

type Kode = { id: number; name: string; className?: string };

export const statusStore: Kode[] = [
  { id: 1, name: "Modtaget", className: "bg-success" },
  { id: 2, name: "Afsluttet", className: "bg-danger" },
  { id: 3, name: "Afventer indberetter", className: "bg-warning" },
  { id: 4, name: "Afventer anden myndighed", className: "bg-warning" },
  { id: 5, name: "Under behandling", className: "bg-success" },
  { id: 10, name: "Oprettet", className: "bg-primary" },
  { id: 11, name: "Behandling PD", className: "bg-warning" },
  { id: 12, name: "I høring", className: "bg-horing" },
  { id: 13, name: "Behandling GST", className: "bg-warning" },
  { id: 14, name: "Klarmeldt til DagiSys", className: "bg-warning" },
  { id: 15, name: "Afsluttet GST", className: "bg-danger" },
  { id: 16, name: "Afsluttet", className: "bg-danger" },
  { id: 20, name: "Klarmeldt til SDSYS", className: "bg-warning" },
  { id: 21, name: "Overført til SDSYS", className: "bg-horing" },
];

export const rolleStore: Kode[] = [
  { id: 0, name: "Anonym" },
  { id: 1, name: "Indberetter" },
  { id: 2, name: "Sagsbehandler" },
  { id: 3, name: "Høringsansvarlig" },
  { id: 4, name: "Høringspart" },
  { id: 5, name: "Høringsansvarlig" },
];

export const prioritetStore: Kode[] = [
  { id: -1, name: "Ikke prioriteret" },
  { id: 0, name: "Ikke prioriteret" },
  { id: 1, name: "Lav" },
  { id: 2, name: "Mellem" },
  { id: 3, name: "Høj" },
];

/**
 * StednavneIPs interne nummerering og tekster, der benyttes i dropdown i UI
 */
export const Kategori = [
  { value: 3100, label: "Bebyggelse" },
  //{ value: 6323, text: "Begravelsesplads" },
  { value: 4000, label: "Bygning" },
  { value: 5400, label: "Fortidsminde" },
  //{ value: 5520, text: "Friluftsbad" },
  //{ value: 2459, text: "Færgerute" },
  { value: 5510, label: "Idrætsanlæg" },
  { value: 6200, label: "Landskabsform" },
  //{ value: 2500, text: "Lufthavn" },
  { value: 6100, label: "Naturareal" },
  //{ value: 2460, text: "Navigationsanlæg" },
  //{ value: 7100, text: "Farvand" },
  { value: 7219, label: "Sø" },
  { value: 7310, label: "Vandløb" },
  { value: 9999, label: "Andet" },
];

/**
 * mapning mellem tekster fra Geosearh og StednavneIPs interne nummerering
 */
export const KategoriServiceMapning = [
  { value: 3100, label: "bebyggelser" },
  //{ value: 6323, text: "begravelsesplads" },
  { value: 4000, label: "bygning" },
  { value: 5400, label: "fortidsminde" },
  //{ value: 5520, text: "friluftsbad" },
  //{ value: 2459, text: "faergerute" },
  { value: 5510, label: "idraetsanlæg" },
  { value: 6200, label: "landskabsform" },
  //{ value: 2500, text: "lufthavn" },
  { value: 6100, label: "naturareal" },
  //{ value: 2460, text: "navigationsanlæg" },
  //{ value: 7100, text: "farvand" },
  { value: 7219, label: "soe" },
  { value: 7310, label: "vandloeb" },
];

export const filtersForRolle: {
  rolleId: number;
  filterValues: number[];
}[] = [
  { rolleId: 0, filterValues: [0, 9] }, // Gæst
  { rolleId: 1, filterValues: [0, 3, 4, 6, 9] }, // Indberetter
  { rolleId: 2, filterValues: [0, 2, 3, 4, 5, 6, 9] }, // Sagsbehandler
];

export function getFilterValuesByRolleId(rolleId: number) {
  const filter = filtersForRolle.find((f) => f.rolleId === rolleId);
  return filter ? filter : filtersForRolle[0];
}
