import BreadCrumbs from "components/common/BreadCrumbs/BreadCrumbs";
import DemoSkiftBruger from "components/common/DemoSkiftBruger/DemoSkiftBruger";
import Skat from "components/icons/Skat";
import {
  AuthContext,
  fipDemoUsers,
  stednavnDemoUsers,
} from "context/AuthProvider";
import { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { routes } from "routes/routesConfig";
import { authenticationMode, loginUrl, logoutUrl } from "api";
import { AuthenticationMode } from "api/auth";

export default function Header() {
  const { pathname } = useLocation();
  const appRoot = pathname.split("/")[1];
  let routeSet = {
    root: routes.kort,
    indberet: routes.kortIndberet,
    indberetning: routes.kortIndberetning,
    indberetninger: routes.kortIndberetninger,
  };
  switch (appRoot) {
    case "kort":
      break;
    case "stednavn":
      routeSet = {
        root: routes.stednavn,
        indberet: routes.stednavnIndberet,
        indberetning: routes.stednavnIndberetning,
        indberetninger: routes.stednavnIndberetninger,
      };
      break;
    case "postnr":
      routeSet = {
        root: routes.postnr,
        indberet: routes.postnrIndberet,
        indberetning: routes.postnrIndberetning,
        indberetninger: routes.postnrIndberetninger,
      };
      break;
    case "sogn":
      routeSet = {
        root: routes.sogn,
        indberet: routes.sognIndberet,
        indberetning: routes.sognIndberetning,
        indberetninger: routes.sognIndberetninger,
      };
      break;
  }

  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const loginA = document.createElement("a");
  return (
    <>
      {/* <header> */}
      <header
        className="navbar navbar-expand-lg navbar-light bg-white py-4"
        role="navigation"
      >
        <div className="container pe-xl-5 me-xl-0">
          <Link
            to={routeSet.root.path}
            className="navbar-brand d-flex align-items-center"
            aria-label={`Logo SDFE (Styrrelsen for dataforsyning), Indberet - ${routeSet.root.name}`}
          >
            <Skat />
            <span className="me-2 pe-2 border-end fw-semibold small">
              Indberet
            </span>
            <span className="strong fw-light small">
              <strong>{routeSet.root.name}</strong>
            </span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse mt-2 mt-lg-0 justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 align-items-center py-2">
              <li className="nav-item w-100">
                <NavLink
                  to={routeSet.indberet.path}
                  className="nav-link btn btn-outline-grey w-100"
                  aria-current="page"
                >
                  Indberet
                </NavLink>
              </li>
              <li className="nav-item w-100 mt-2 mt-lg-0">
                <NavLink
                  to={routeSet.indberetninger.path}
                  className="nav-link btn btn-outline-grey w-100"
                  aria-current="page"
                >
                  Oversigt
                </NavLink>
              </li>
              {authenticationMode === AuthenticationMode.forms && (
                <li className="nav-item w-100 mt-2 mt-lg-0 ms-lg-2">
                  {authState.currentUser.rolleId === 0 && (
                    <button
                      type="button"
                      className="c-login-button btn btn-icon btn-lg btn-primary text-white"
                      onClick={() => {
                        loginA.setAttribute(
                          "href",
                          loginUrl + "?returnUrl=" + pathname
                        );
                        loginA.click();
                      }}
                      aria-label="Log Ind"
                      title="Log Ind"
                    >
                      <span className="d-lg-none me-2">Log Ind</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-lock-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                      </svg>
                    </button>
                  )}
                  {authState.currentUser.rolleId !== 0 && (
                    <button
                      type="button"
                      className="c-login-button btn btn-icon btn-lg btn-primary text-white"
                      onClick={() => {
                        loginA.setAttribute(
                          "href",
                          logoutUrl + "?returnUrl=/" + appRoot
                        );
                        loginA.click();
                      }}
                      aria-label="Log Ud"
                      title={"Log Ud (" + authState.currentUser.navn + ")"}
                    >
                      {
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="ms-2 bi bi-unlock-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                        </svg>
                      }

                      <span className="d-lg-none me-2">Log Ud</span>
                    </button>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
      {/* </header> */}
      <div className="container pe-xl-5 me-xl-0 ms-xl-auto d-flex">
        <BreadCrumbs />
      </div>
      {authenticationMode === AuthenticationMode.none && appRoot === "kort" && (
        <DemoSkiftBruger
          brugere={fipDemoUsers}
          currentBruger={currentUser}
          setCurrentBruger={(bruger) => {}}
        />
      )}
      {authenticationMode === AuthenticationMode.none &&
        appRoot === "stednavn" && (
          <DemoSkiftBruger
            brugere={stednavnDemoUsers}
            currentBruger={currentUser}
            setCurrentBruger={(bruger) => {}}
          />
        )}
    </>
  );
}
