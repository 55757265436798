import PlusCircleFill from "components/icons/PlusCircleFill";
import { ForwardedRef, forwardRef, useRef } from "react";

const FileInput = forwardRef(
  (
    props: {
      disabled?: boolean;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
      marginBottom?: number;
    },
    ref: ForwardedRef<HTMLLabelElement>
  ) => {
    const { disabled, onChange, marginBottom } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    return (
      <div className={`c-upload-file`}>
        <label
          ref={ref}
          htmlFor="formFile"
          className={`form-label ${
            marginBottom !== undefined ? "mb-" + marginBottom : ""
          } ${disabled ? "disabled" : ""}`}
          tabIndex={0}
          onKeyDown={(e) => {
            switch (e.key) {
              case " ":
              case "Enter": {
                e.preventDefault();
                if (inputRef.current) {
                  inputRef.current.click();
                }
                break;
              }
              default:
                break;
            }
          }}
        >
          <PlusCircleFill class="me-2" />
          Upload fil
        </label>
        <input
          disabled={disabled}
          ref={inputRef}
          onChange={onChange}
          className="form-control visually-hidden"
          type="file"
          id="formFile"
        />
      </div>
    );
  }
);

export default FileInput;
