import DrawInstructions from "components/common/Indberet/DrawInstructions";
import LoadingSpinner from "components/common/LoadingSpinner";
import PlusCircleFill from "components/icons/PlusCircleFill";
import { DrawTool } from "context/MapProvider";
import { Fil, NewFileState } from "routes/Fip/FipIndberet";
import IconDrawPolygon from "components/icons/DrawPolygon";
import ThreeDotsVertical from "components/icons/ThreeDotsVertical";
import Trash from "components/icons/Trash";
import { formatDashedDate, formatDateString } from "util/date";
import { useRef } from "react";
import ModalConfirmDelete from "components/common/Indberet/ModalConfirmDelete";
import { ShowModalSignature } from "components/common/Modal/Modal";
import FileInput from "components/common/FormElements/FileInput";
import GeometryAvatar from "components/common/GeometryAvatar";
import { SagsbehandlingApi } from "api/interfaces";
import { uniqueId } from "lodash";

export default function File(props: {
  setDrawTool: (drawTool: DrawTool) => void;
  fil: Fil | undefined;
  newFile: NewFileState;
  setNewFile: (newFile: NewFileState) => void;
  activeDrawTool: DrawTool;
  hoveredFeatures: (string | number)[];
  setHoveredFeatures: (hoveredFeatureIds: (string | number)[]) => void;
  onClick: (f) => void;
  removeFil: () => void;
  onEditGeometryClick: () => void;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
  sagsbehandlingApi: SagsbehandlingApi;
}) {
  const {
    fil,
    newFile,
    setNewFile,
    activeDrawTool,
    setDrawTool,
    hoveredFeatures,
    setHoveredFeatures,
    onClick,
    removeFil,
    onEditGeometryClick,
    showModal,
    closeModal,
    sagsbehandlingApi,
  } = props;
  const { uploadFile } = sagsbehandlingApi;
  const fileInputRef = useRef<HTMLLabelElement>(null);
  const id = fil?.geometri.get("id");
  const dropdownMenuButtonId = uniqueId("dropdownMenuButton");
  return (
    <section className={`l-section`}>
      {/* section head */}
      <h2>Upload fil</h2>
      <p>Det er frivilligt om du vil tilføje filer til din indberetning.</p>
      {!fil && (
        <span className="d-block bg-tint-primary text-shade-primary fw-normal py-2 px-3 mb-3">
          Du har ikke vedlagt en fil
        </span>
      )}
      {fil && (
        <>
          <span className="form-label d-block">Vedlagt fil</span>
          {/* FilListItem */}
          <div
            className={`list-group-item list-group-item--card p-3 ${
              hoveredFeatures.indexOf(id) > -1 ? "hover" : ""
            }`}
            tabIndex={0}
            onMouseEnter={() => setHoveredFeatures([id])}
            onMouseLeave={() => setHoveredFeatures([])}
            onClick={onClick}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onClick(fil.geometri);
              }
            }}
          >
            <button
              type="button"
              className={`list-group-item__action ${
                hoveredFeatures.indexOf(id) > -1 ? "hover" : ""
              }`}
              aria-label="Vis i kort"
              title=""
            ></button>
            <div className="row align-items-top">
              <div className="col-auto d-none d-md-flex">
                <GeometryAvatar feature={fil.geometri} />
              </div>
              <div className="col ms-md-n2 text-truncate">
                <h4 className="mb-0 text-truncate name">
                  {fil.originalFilNavn}
                </h4>
                <p aria-hidden className="mb-0 small">
                  {formatDateString(fil.dato)}
                </p>
                <p className="visually-hidden">{formatDashedDate(fil.dato)}</p>
                <p className="mb-0">{fil.beskrivelse}</p>
              </div>
              <div className="col-auto">
                <div className="dropdown">
                  <button
                    className="btn btn-icon btn-outline-primary"
                    aria-label="Funktioner"
                    title="Funktioner"
                    type="button"
                    id={dropdownMenuButtonId}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ThreeDotsVertical />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby={dropdownMenuButtonId}
                  >
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        aria-label="Ret geometri"
                        title="Ret geometri"
                        onClick={onEditGeometryClick}
                      >
                        <IconDrawPolygon class="me-2" />
                        Ret geometri
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        aria-label="Slet"
                        title="Slet"
                        onClick={(e) => {
                          e.stopPropagation();
                          showModal({
                            title: "Slet fil",
                            content: (
                              <ModalConfirmDelete
                                type={"fil"}
                                confirm={() => {
                                  removeFil();
                                }}
                                closeModal={closeModal}
                              />
                            ),
                          });
                        }}
                      >
                        <Trash class="me-2" />
                        Slet
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* section content - component draw */}
      <div className="c-upload-file mb-3">
        {newFile.state === "IDLE" && !fil && (
          <FileInput
            ref={fileInputRef}
            onChange={async (e) => {
              setNewFile({ state: "UPLOADING", file: undefined });
              const { files } = e.target;
              if (files && files.length) {
                const file = files[0];
                let formData = new FormData();
                formData.append("file", file);
                const resp = await uploadFile(formData);
                setNewFile({
                  state: "UPLOADED",
                  file: {
                    id: resp.id,
                    originalFilNavn: resp.originalFilNavn,
                  },
                });
                setDrawTool("point");
              }
            }}
          />
        )}
        {newFile.state === "UPLOADING" && <LoadingSpinner />}
        {newFile.state === "UPLOADED" && newFile.file && (
          <>
            <div
              className="alert alert-primary bg-transparent border-2"
              role="alert"
            >
              <h4 className="alert-heading">
                <strong>Afslut upload:</strong> Placer filen i kortet
              </h4>
              <p>
                Filen <strong>{newFile.file.originalFilNavn}</strong> skal
                tilknyttes en placering i kortet før din upload er afsluttet.
              </p>
              <div className="mt-2 gap-2 d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-danger text-white"
                  onClick={() => {
                    setNewFile({ state: "IDLE", file: undefined });
                    setDrawTool(undefined);
                  }}
                >
                  <PlusCircleFill class="me-2 rotate-45" />
                  Fortryd upload
                </button>
                <DrawInstructions activeDrawTool={activeDrawTool} />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
