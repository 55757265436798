import { Tema } from "interfaces/IIndberetning";
import {
  bygningerWFSLayer,
  topoSkærmkort,
  fipSagWMSLayer,
  ortofoto,
  geodanmark,
  topoSkærmkortGraa,
  kommunegrænser,
  fireCmHistorisk,
  højeMålestoksblade,
  laveMålestoksblade,
  adresser,
  matrikler,
  vejogstimidteWFSLayer,
  kort25,
  kort50,
  kort100,
  kort200,
  dhmOverflade,
  dhmTerræn,
  udvalgteGeoDKTemaer,
  vejkantWFSLayer,
  søWFSLayer,
  vandløbWFSLayer,
} from "./layers";

const defaultLayerSet = [
  {
    loading: false,
    maplayer: kommunegrænser,
    title: kommunegrænser.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: adresser,
    title: adresser.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: matrikler,
    title: matrikler.get("title"),
    visible: false,
  },
  {
    loading: false,
    maplayer: vejogstimidteWFSLayer,
    title: vejogstimidteWFSLayer.get("title"),
    visible: false,
    featuresSelectable: true,
  },
  {
    loading: false,
    maplayer: bygningerWFSLayer,
    title: bygningerWFSLayer.get("title"),
    visible: false,
    featuresSelectable: true,
  },
  {
    loading: false,
    maplayer: fipSagWMSLayer,
    title: fipSagWMSLayer.get("title"),
    visible: true,
  },
];
export const tema: Tema[] = [
  {
    name: "Oversigt",
    id: 1,
    domainId: 0,
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: "GeoDanmark",
        visible: true,
      },
      {
        loading: false,
        maplayer: topoSkærmkort,
        title: "Skærmkort",
        visible: false,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: "Ortofoto",
        visible: false,
      },
    ],
    layers: [
      {
        loading: false,
        maplayer: kommunegrænser,
        title: kommunegrænser.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: fipSagWMSLayer,
        title: fipSagWMSLayer.get("title"),
        visible: true,
      },
    ],
    baseLayerId: "baseGeoDanmark",
  },
  {
    name: "Skærmkort",
    id: 2,
    domainId: 1,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: "GeoDanmark",
        visible: false,
      },
      {
        loading: false,
        maplayer: topoSkærmkort,
        title: "Skærmkort",
        visible: true,
      },
      {
        loading: false,
        maplayer: topoSkærmkortGraa,
        title: "Skærmkort gråtone",
        visible: false,
      },
      {
        loading: false,
        maplayer: fireCmHistorisk,
        title: fireCmHistorisk.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: højeMålestoksblade,
        title: højeMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: laveMålestoksblade,
        title: laveMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: "Ortofoto",
        visible: false,
      },
    ],
    layers: defaultLayerSet,
  },
  {
    name: "Kort25",
    id: 4,
    domainId: 1,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: fireCmHistorisk,
        title: fireCmHistorisk.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: højeMålestoksblade,
        title: højeMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: laveMålestoksblade,
        title: laveMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort25,
        title: kort25.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: ortofoto.get("title"),
        visible: false,
      },
    ],
    layers: defaultLayerSet,
  },
  {
    name: "Kort50",
    id: 5,
    domainId: 1,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: højeMålestoksblade,
        title: højeMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: laveMålestoksblade,
        title: laveMålestoksblade.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort25,
        title: kort25.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort50,
        title: kort50.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: ortofoto.get("title"),
        visible: false,
      },
    ],
    layers: defaultLayerSet,
  },
  {
    name: "Kort100",
    id: 6,
    domainId: 1,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort50,
        title: kort50.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort100,
        title: kort100.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: ortofoto.get("title"),
        visible: false,
      },
    ],
    layers: defaultLayerSet,
  },
  {
    name: "Kort200 - Færdselskort",
    id: 7,
    domainId: 1,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort100,
        title: kort100.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: kort200,
        title: kort200.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: ortofoto.get("title"),
        visible: false,
      },
    ],
    layers: defaultLayerSet,
  },
  {
    name: "GeoDanmark",
    id: 8,
    domainId: 10,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: topoSkærmkort,
        title: "Skærmkort",
        visible: false,
      },
      {
        loading: false,
        maplayer: ortofoto,
        title: ortofoto.get("title"),
        visible: false,
      },
    ],
    layers: [
      {
        loading: false,
        maplayer: adresser,
        title: adresser.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: udvalgteGeoDKTemaer,
        title: udvalgteGeoDKTemaer.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: bygningerWFSLayer,
        title: bygningerWFSLayer.get("title"),
        visible: false,
        featuresSelectable: true,
      },
      {
        loading: false,
        maplayer: vejogstimidteWFSLayer,
        title: vejogstimidteWFSLayer.get("title"),
        visible: false,
        featuresSelectable: true,
      },
      {
        loading: false,
        maplayer: vejkantWFSLayer,
        title: vejkantWFSLayer.get("title"),
        visible: false,
        featuresSelectable: true,
      },
      {
        loading: false,
        maplayer: søWFSLayer,
        title: søWFSLayer.get("title"),
        visible: false,
        featuresSelectable: true,
      },
      {
        loading: false,
        maplayer: vandløbWFSLayer,
        title: vandløbWFSLayer.get("title"),
        visible: false,
        featuresSelectable: true,
      },
      {
        loading: false,
        maplayer: matrikler,
        title: matrikler.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: fipSagWMSLayer,
        title: fipSagWMSLayer.get("title"),
        visible: true,
      },
    ],
  },
  {
    name: "Danmarks Højdemodel (DHM)",
    id: 9,
    domainId: 3,
    baseLayerId: "baseGeoDanmark",
    baseLayers: [
      {
        loading: false,
        maplayer: geodanmark,
        title: geodanmark.get("title"),
        visible: false,
      },
      {
        loading: false,
        maplayer: dhmOverflade,
        title: dhmOverflade.get("title"),
        visible: true,
      },
      {
        loading: false,
        maplayer: dhmTerræn,
        title: dhmTerræn.get("title"),
        visible: false,
      },
    ],
    layers: [
      {
        loading: false,
        maplayer: fipSagWMSLayer,
        title: fipSagWMSLayer.get("title"),
        visible: true,
      },
    ],
  },
];
type Kode = { id: number; name: string; className?: string };

export const statusStore: Kode[] = [
  { id: 1, name: "Modtaget", className: "bg-warning" },
  { id: 2, name: "Afsluttet", className: "bg-danger" },
  { id: 3, name: "Afventer indberetter", className: "bg-warning" },
  { id: 4, name: "Afventer anden myndighed", className: "bg-warning" },
  { id: 5, name: "Under behandling", className: "bg-success" },
  { id: 10, name: "Oprettet", className: "bg-primary" },
  { id: 11, name: "Behandling PD", className: "bg-warning" },
  { id: 12, name: "I høring", className: "bg-horing" },
  { id: 13, name: "Behandling GST", className: "bg-warning" },
  { id: 14, name: "Klarmeldt til DagiSys", className: "bg-warning" },
  { id: 15, name: "Afsluttet GST", className: "bg-danger" },
  { id: 16, name: "Afsluttet", className: "bg-danger" },
  { id: 20, name: "Klarmeldt til SDSYS", className: "bg-warning" },
  { id: 21, name: "Overført til SDSYS", className: "bg-horing" },
];

export const rolleStore: Kode[] = [
  { id: 0, name: "Anonym" },
  { id: 1, name: "Indberetter" },
  { id: 2, name: "Sagsbehandler" },
  { id: 3, name: "Høringsansvarlig" },
  { id: 4, name: "Høringspart" },
  { id: 5, name: "Høringsansvarlig" },
];

export const prioritetStore: Kode[] = [
  { id: 0, name: "Ikke prioriteret" },
  { id: 1, name: "Lav" },
  { id: 2, name: "Mellem" },
  { id: 3, name: "Høj" },
];

export type DomeneType =
  | { id: -1; name: "Ukendt domenetype" }
  | { id: 1; name: "Landkort" }
  | { id: 2; name: "Søkort" }
  | { id: 3; name: "Højdemodel" }
  | { id: 4; name: "Ortofotos" }
  | { id: 5; name: "Stednavn" }
  | { id: 6; name: "Matrikel" }
  | { id: 7; name: "Fikspunkt" }
  | { id: 8; name: "Postnummer" }
  | { id: 9; name: "Sogn" }
  | { id: 10; name: "GeoDanmark" };

export const domeneTyper: DomeneType[] = [
  { id: 1, name: "Landkort" },
  { id: 2, name: "Søkort" },
  { id: 3, name: "Højdemodel" },
  { id: 4, name: "Ortofotos" },
  { id: 5, name: "Stednavn" },
  { id: 6, name: "Matrikel" },
  { id: 7, name: "Fikspunkt" },
  { id: 8, name: "Postnummer" },
  { id: 9, name: "Sogn" },
  { id: 10, name: "GeoDanmark" },
];
export function getDomeneTypeById(id: number) {
  const dt = domeneTyper.find((f) => f.id === id);
  return dt ? dt : { id: -1, name: "Ukendt domenetype" };
}

// todo: remove?
export const domaeneStore: Kode[] = [
  { id: 1, name: "Landkort" },
  { id: 2, name: "Søkort" },
  { id: 3, name: "Højdemodel" },
  { id: 4, name: "Ortofotos" },
  { id: 5, name: "Stednavn" },
  { id: 6, name: "Matrikel" },
  { id: 7, name: "Fikspunkt" },
  { id: 8, name: "Postnummer" },
  { id: 9, name: "Sogn" },
  { id: 10, name: "GeoDanmark" },
];

export const filtersForRolle: {
  rolleId: number;
  filterValues: number[];
}[] = [
  { rolleId: 0, filterValues: [0, 9] }, // Gæst
  { rolleId: 1, filterValues: [0, 3, 4, 6, 9] }, // Indberetter
  { rolleId: 2, filterValues: [0, 1, 2, 3, 4, 5, 6, 9] }, // Sagsbehandler
  { rolleId: 4, filterValues: [0, 3, 4, 6, 9] }, // Høringspart
];

export function getFilterValuesByRolleId(rolleId: number) {
  const filter = filtersForRolle.find((f) => f.rolleId === rolleId);
  return filter ? filter : filtersForRolle[0];
}
