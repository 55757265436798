import Fip from "routes/Fip/Fip";
import Stednavn from "routes/Stednavn/Stednavn";
import FipOversigt from "routes/Fip/FipOversigt";
import FipIndberet from "routes/Fip/FipIndberet";
import FipIndberetning from "routes/Fip/FipIndberetning";
import StednavnIndberet from "routes/Stednavn/StednavnIndberet";
import StednavnIndberetninger from "routes/Stednavn/StednavnIndberetninger";
import StednavnIndberetning from "routes/Stednavn/StednavnIndberetning";
import Postnr from "./Postnr/Postnr";
import PostnrIndberet from "./Postnr/PostnrIndberet";
import PostnrIndberetning from "./Postnr/PostnrIndberetning";
import PostnrIndberetninger from "./Postnr/PostnrIndberetninger";
import Sogn from "./Sogn/Sogn";
import SognIndberet from "./Sogn/SognIndberet";
import SognIndberetninger from "./Sogn/SognIndberetninger";
import SognIndberetning from "./Sogn/SognIndberetning";
import CreateStednavn from "./Stednavn/StednavnIndberet/CreateStednavn";
import UpdateStednavn from "./Stednavn/StednavnIndberet/UpdateStednavn";
import DeleteStednavn from "./Stednavn/StednavnIndberet/DeleteStednavn";

export const routes = {
  kort: {
    path: "/kort",
    isPublic: true,
    name: "Opdateringer i kort",
    Component: Fip,
  },
  kortIndberet: {
    path: "/kort/indberet",
    name: "Indberet",
    isPublic: false,
    Component: FipIndberet,
  },
  kortIndberetninger: {
    path: "/kort/indberetninger",
    name: "Indberetninger",
    isPublic: true,
    Component: FipOversigt,
  },
  kortIndberetning: {
    path: "/kort/indberetninger/:id",
    name: "Indberetning",
    isPublic: true,
    Component: FipIndberetning,
  },
  stednavn: {
    path: "/stednavn",
    name: "Stednavn",
    isPublic: true,
    Component: Stednavn,
  },
  stednavnIndberet: {
    path: "/stednavn/indberet",
    name: "Indberet",
    isPublic: false,
    Component: StednavnIndberet,
  },
  stednavnIndberetCreate: {
    path: "/stednavn/indberet/create",
    name: "Opret stednavn",
    isPublic: false,
    Component: CreateStednavn,
  },
  stednavnIndberetUpdate: {
    path: "/stednavn/indberet/update",
    name: "Ændre stednavn",
    isPublic: false,
    Component: UpdateStednavn,
  },
  stednavnIndberetDelete: {
    path: "/stednavn/indberet/delete",
    name: "Nedlæg stednavn",
    isPublic: false,
    Component: DeleteStednavn,
  },
  stednavnIndberetninger: {
    path: "/stednavn/indberetninger",
    name: "Indberetninger",
    isPublic: false,
    Component: StednavnIndberetninger,
  },
  stednavnIndberetning: {
    path: "/stednavn/indberetninger/:id",
    name: "Indberetning",
    isPublic: false,
    Component: StednavnIndberetning,
  },
  postnr: {
    path: "/postnr",
    name: "Postnummer",
    isPublic: false,
    Component: Postnr,
  },
  postnrIndberet: {
    path: "/postnr/indberet",
    name: "Indberet",
    isPublic: false,
    Component: PostnrIndberet,
  },
  postnrIndberetninger: {
    path: "/postnr/indberetninger",
    name: "Indberetninger",
    isPublic: false,
    Component: PostnrIndberetninger,
  },
  postnrIndberetning: {
    path: "/postnr/indberetninger/:id",
    name: "Indberetning",
    isPublic: false,
    Component: PostnrIndberetning,
  },
  sogn: { path: "/sogn", name: "Sogne", isPublic: false, Component: Sogn },
  sognIndberet: {
    path: "/sogn/indberet",
    name: "Indberet",
    isPublic: false,
    Component: SognIndberet,
  },
  sognIndberetninger: {
    path: "/sogn/indberetninger",
    name: "Indberetninger",
    isPublic: false,
    Component: SognIndberetninger,
  },
  sognIndberetning: {
    path: "/sogn/indberetninger/:id",
    name: "Indberetning",
    isPublic: false,
    Component: SognIndberetning,
  },
};
export const routesArray: {
  path: string;
  name: string;
  isPublic: boolean;
  Component: React.ComponentType<any>;
  key: string;
}[] = Object.keys(routes).map((key) => {
  return { ...routes[key], key };
});

export const paths: any = routesArray.reduce((prev, curr) => {
  prev[curr.key] = curr.path;
  return prev;
}, {});
