import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { convertWktToFeature } from "../util/ol";
import { FipLayer } from "./FipLayer";

export interface IndberetningerState {
  indberetninger: Indberetning[];
  state: State;
}
export interface IndberetningState {
  indberetning?: Hoeringssag;
  features?: Feature<any>[];
  state: State;
}

/**
 * Validation object
 */
export interface Validation {
  /**
   * Did validation succeed?
   */
  valid: boolean;
  /**
   * Message to show user in case validation failed
   */
  message: string;
  /**
   * Usually, we don't show validation error until after field has been touched
   */
  touched: boolean;
  /**
   * Flag used to trigger Voice Over to read message
   */
  notify: boolean;
}

export enum DomaeneTypeIdEnum {
  landkort = 1,
  søkort = 2,
  højdemodal = 3,
  ortofotos = 4,
  stednavn = 5,
  matrikel = 6,
  fikspunkt = 7,
  postnummer = 8,
  sogn = 9,
  geoDanmark = 10,
}
export enum PrioritetIdEnum {
  ikkePrioriteret = 0,
  lav = 1,
  mellem = 2,
  hoej = 3,
}

export interface IndberetningDTO {
  bbox: string;
  beskrivelse: string;
  domaeneType: string;
  domaeneTypeId: DomaeneTypeIdEnum;
  emne: string;
  fraTs: string;
  frist: string;
  id: number;
  isOwner: boolean;
  oprettetTs: string;
  prioritet: number;
  sagsnummer: any;
  statusId: number;
}

export interface RedigerIndberetningDTO {
  frist: string | null;
  domaeneType: string;
  domaeneTypeId: DomaeneTypeIdEnum;
  prioritet: PrioritetIdEnum;
}
export interface ExpandedIndberetningDTO extends IndberetningDTO {
  esdhJournalId: string;
  esdhJournalNummer: string;
  hoeringsansvarligmappe: any;
  hoeringspartmappe: any;
  indberetningsmappe: any;
  korrespondance: any;
  ressource: Ressource[];
  sagsbehandlermappe: any;
}

export interface Indberetning {
  bbox?: Feature<Geometry>;
  beskrivelse: string;
  domaeneType: string;
  domaeneTypeId: DomaeneTypeIdEnum;
  emne: string;
  fraTs?: Date;
  frist?: Date;
  id: number;
  isOwner: boolean;
  oprettetTs: Date;
  prioritet: number;
  sagsnummer: any;
  statusId: number;
}

export const convertIndberetningDTO = (
  input: IndberetningDTO
): Indberetning => {
  const {
    bbox,
    beskrivelse,
    domaeneType,
    domaeneTypeId,
    emne,
    fraTs,
    frist,
    id,
    isOwner,
    oprettetTs,
    prioritet,
    sagsnummer,
    statusId,
  } = input;
  return {
    bbox: bbox ? convertWktToFeature(bbox) : undefined,
    beskrivelse,
    domaeneType,
    domaeneTypeId,
    emne,
    fraTs: fraTs ? new Date(fraTs) : undefined,
    frist: frist ? new Date(frist) : undefined,
    id,
    isOwner,
    oprettetTs: new Date(oprettetTs),
    prioritet,
    sagsnummer,
    statusId,
  };
};

export interface Hoeringssag {
  bbox: string; // ""
  beskrivelse: string;
  domaeneType: string;
  domaeneTypeId: DomaeneTypeIdEnum; // 1;
  emne: string; // "noget er galt";
  esdhJournalId: string; // "";
  esdhJournalNummer: string; // "";
  fraTs: string | null; // null;
  frist: string | null; // null;
  id: number; // 0;
  indberetningsmappe: Indberetningsmappe[];
  korrespondance: Indlaeg[];
  oprettetTs: string | null; // ;
  prioritet: number; // 0;
  ressource: Ressource[];
  sagsbehandlermappe: Sagsbehandlermappe[];
  sagsid: number; // 0;
  sagsnummer: string; // "";
  statusId: number; // 1;
  tilTs: string | null; // null;
  hoeringsansvarligmappe?: Hoeringsmappe[];
  hoeringspartmappe?: Hoeringsmappe[];
}

export const isHoeringssag = (sag: Hoeringssag): sag is Hoeringssag => {
  return (
    sag.hoeringsansvarligmappe !== undefined &&
    sag.hoeringsansvarligmappe.length > 0
  );
};

export enum statusIdEnum {
  modtaget = 1,
  afsluttet = 2,
  afventerIndberetter = 3,
  afventerAndenMyndighed = 4,
  underBehandling = 5,
  oprettet = 10,
  behandlingPD = 11,
  iHoering = 12,
  behandlingGST = 13,
  klarmeldtTilDagiSys = 14,
  afsluttetGST = 15,
  klarmeldtTilSDSYS = 20,
  overførtTilSDSYS = 21,
}

export interface Indlaeg {
  id: number;
  typeId: IndlaegTypeIdEnum;
  typeNavn: string;
  personId: number;
  personNavn: string;
  organisationId: number;
  organisationNavn: string;
  organisationForkortelse: string;
  indhold: string;
  ts: string;
}

export enum IndlaegTypeIdEnum {
  intern = 1,
  overdragelseskommentar = 2,
  tilIndberetter = 3,
  fraIndberetter = 4,
  afslutningsKommentar = 5,
  beskedFraHoeringsAnsvarlig = 6,
  beskedTiLHoeringsAnsvarlig = 7,
  beskedFraHoeringspart = 8,
  beskedTilHoeringspart = 9,
  fraSagsbehandler = 10,
  tilSagsbehandler = 11,
}

export interface Ressource {
  id: number;
  personId: number;
  navn: string;
  email: string;
  organisationId: number;
  organisationsnavn: string;
  fraTs: string;
  tilTs: string;
  rolleId: RessourceRolleIdEnum;
}
export enum RessourceRolleIdEnum {
  gæst = 0,
  indberetter = 1,
  sagsbehandler = 2,
  esdh = 3,
  høringspart = 4,
  høringsansvarlig = 5,
}

export interface Indberetningsmappe {
  beskrivelse: string; // "noget eeeeer galt";
  domaeneType: string; // ""
  domaeneTypeId: DomaeneTypeIdEnum; // 10
  emne: string; // "fritekst"
  fejlSet: string; // ""
  fil: Sagsakt[];
  geometri: Aendringsforslag[];
  id: number; // 0;
  korttemaId: number; // 4;
}

export interface Sagsbehandlermappe {
  fil: Sagsakt[];
  geometri: Aendringsforslag[];
  id: number; // 0;
}

export interface Hoeringsmappe {
  id: number;
  organisationId: number;
  organisationNavn: string;
  svar: string;
  svarStatusId: number;
  svarPersonId: number;
  svarPersonNavn: string;
  svarFrist: string;
  ts: string;
  geometri: Aendringsforslag[] | null;
  fil: Sagsakt[] | null;
}

export interface Sagsakt {
  beskrivelse: string;
  dato: string;
  filId: number;
  filreference: string;
  geometri: string;
  geometriId?: number;
  geometriType: string;
  id: number;
  kortforsyningId: string;
  mimetype: string;
  nr: number;
  openLayersId: string;
  originalFilNavn: string;
  personId: number;
  personNavn: string;
  supplInfo: string;
  udpeget: boolean;
  url: string;
  openlayersFeature?: Feature<Geometry>;
}
export interface Aendringsforslag extends Sagsakt {
  aendringType: string;
  aendringTypeId: number;
  featureType: string;
  featureTypeId: number;
}

export const isFipLayer = (
  input: FipLayerFolder | FipLayer
): input is FipLayer => {
  return input["layers"] === undefined;
};
export interface FipLayerFolder {
  title: string;
  layers: FipLayer[];
}

export interface Tema {
  name: string;
  id: number;
  domainId: number;
  baseLayerId: string;
  baseLayers: FipLayer[];
  layers: (FipLayer | FipLayerFolder)[];
}

export interface IFileUploadResponse {
  success: boolean;
  id: string;
  originalFilNavn: string;
  errormsg: string;
}
export type SearchFilter =
  | { value: 0; text: "Aktive" }
  | { value: 1; text: "Uhåndterede" }
  | { value: 2; text: "Afsluttede" }
  | { value: 3; text: "Egne" }
  | { value: 4; text: "Alle" }
  | { value: 5; text: "Nye" }
  | { value: 6; text: "Alle indenfor kortvindue" }
  | { value: 9; text: "Søgning" };

export const AllSearchFilters: SearchFilter[] = [
  { value: 0, text: "Aktive" },
  { value: 1, text: "Uhåndterede" },
  { value: 2, text: "Afsluttede" },
  { value: 3, text: "Egne" },
  { value: 4, text: "Alle" },
  { value: 5, text: "Nye" },
  { value: 6, text: "Alle indenfor kortvindue" },
  { value: 9, text: "Søgning" },
];

export function getSearchFilterByValue(value: number) {
  const filter = AllSearchFilters.find((f) => f.value === value);
  return filter ? filter : AllSearchFilters[0];
}
