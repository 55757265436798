import { DrawTool } from "context/MapProvider";

export default function DrawInstructions(props: { activeDrawTool: DrawTool }) {
  const { activeDrawTool } = props;
  switch (activeDrawTool) {
    case "point":
      return (
        <div className="small text-muted">
          <strong>Sæt ét punkt</strong> i kortet.
        </div>
      );
    case "line":
      return (
        <div className="small text-muted">
          <strong>Sæt min. 2 punkter</strong> i kortet. <i>Dobbeltklik</i> for
          at afslutte din linje.
        </div>
      );
    case "polygon":
      return (
        <div className="small text-muted">
          <strong>Sæt min. 3 punkter</strong> i kortet. <i>Dobbeltklik</i> for
          at afslutte dit polygon.
        </div>
      );
    case "udpeg":
      return (
        <div className="small text-muted">
          Vælg et objekt ved at <strong>klikke på det i kortet.</strong>
        </div>
      );
  }
  return null;
}
