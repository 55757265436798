import IndberetningListItem from "components/common/Indberetning/IndberetningListItem";
import LoadingSpinner from "components/common/LoadingSpinner";
import Section from "components/common/Section";
import { IndberetningerState, SearchFilter } from "interfaces/IIndberetning";
import { useLayoutEffect, useRef } from "react";

export interface FilterSearch {
  emne: string;
  beskrivelse: string;
}
export default function IndberetningOversigt(props: {
  filter: SearchFilter;
  filters: SearchFilter[];
  setFilter: (filter: SearchFilter) => void;
  searchInputText: FilterSearch;
  indberetningerState: IndberetningerState;
  hideBecauseEmptySearch: boolean;
  setSearchInputText: (t: FilterSearch) => void;
  showDomain: boolean;
  allowSearchBeskrivelse: boolean;
}) {
  const {
    filter,
    filters,
    setFilter,
    setSearchInputText,
    searchInputText,
    indberetningerState,
    hideBecauseEmptySearch,
    showDomain,
    allowSearchBeskrivelse,
  } = props;
  const { indberetninger } = indberetningerState;
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        Indberetninger
      </h1>
      <p className="lead fw-semibold text-dark me-md-6">
        Her kan du se de indberetninger du selv har foretaget samt øvrige
        indberetteres indberetninger.
      </p>
      <Section>
        <div className="row">
          <div className="col-12 col-sm-6">
            <h2 className="mb-0">Indberetninger i kort</h2>
          </div>
          <div className="col-12 col-sm-4 my-2 my-sm-0 ms-auto">
            <select
              className="form-select form-select-sm py-1"
              aria-label="Default select example"
              value={filter.value}
              onChange={(e) => {
                const newfilter = filters.find(
                  (f) => f.value === parseInt(e.target.value)
                );
                if (newfilter) setFilter(newfilter);
              }}
            >
              {filters.map((filter) => {
                return (
                  <option value={filter.value} key={filter.value}>
                    {filter.text}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* Kun visuel hvis den står på søgning i filtre */}
        {filter.text === "Søgning" && (
          <div
            className="row bg-light g-0 mx-0 mt-2 mb-3 p-2"
            aria-live="polite"
          >
            <div
              className={`${allowSearchBeskrivelse ? "col-6" : "col-12"} pe-2`}
            >
              <div className="input-group flex-nowrap w-100">
                <input
                  onChange={(e) => {
                    setSearchInputText({
                      ...searchInputText,
                      emne: e.target.value,
                    });
                  }}
                  value={searchInputText.emne}
                  type="search"
                  className="form-control form-control-sm"
                  placeholder="Filtrér emne"
                  aria-label="Emne"
                  aria-describedby="addon-wrapping"
                />
              </div>
            </div>
            {allowSearchBeskrivelse && (
              <div className="col-6">
                <div className="input-group flex-nowrap w-100">
                  <input
                    onChange={(e) => {
                      setSearchInputText({
                        ...searchInputText,
                        beskrivelse: e.target.value,
                      });
                    }}
                    value={searchInputText.beskrivelse}
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Filtrér beskrivelse"
                    aria-label="Beskrivelse"
                    aria-describedby="addon-wrapping"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="row mb-3">
          <div className="col-12">
            {indberetningerState.state === "IDLE" && (
              <p className="mb-0">
                {!hideBecauseEmptySearch && (
                  <span>
                    {indberetningerState.indberetninger.length} resultater
                  </span>
                )}
              </p>
            )}
          </div>
        </div>
        {/* Indberetninger */}
        {indberetningerState.state === "LOADING" && <LoadingSpinner />}
        {indberetningerState.state === "IDLE" && !hideBecauseEmptySearch && (
          <div className="list-group list-group-flush border-bottom">
            {indberetninger.length === 0 && (
              <div className="p-3 mt-3 bg-light">
                Ingen øvrige indberetninger
              </div>
            )}
            {indberetninger.length > 0 &&
              indberetninger.map((i) => {
                return (
                  <IndberetningListItem
                    indberetning={i}
                    key={i.id}
                    showDomain={showDomain}
                  />
                );
              })}
          </div>
        )}
      </Section>
    </div>
  );
}
